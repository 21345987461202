<template>

  <div>
    <!-- Table Container Card -->

    <b-tabs v-model="tabIndex">
      <b-tab title="Powermeter">
        <b-card
          no-body
          class="mb-0"
        >

          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <b-container>
                    <b-row>
                      <b-col md="4">
                        <b-form-group>
                            <b-form-input
                              v-model="PowerMeterFilter"
                              placeholder="Search"
                              v-on:keyup.enter="filterPowermeter()"
                            />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-button 
                          variant="primary"
                          type="submit"
                          :disabled="pmDisable"
                          @click.prevent="validationForm1"
                        >
                          Save
                        </b-button>
                        <b-button class="ml-1"
                          variant="success"
                          type="submit"
                          :disabled="pmDisable"
                          @click.prevent="synTableMES"
                        >
                          <div v-if="pmDisable">
                            <b-spinner small></b-spinner>
                              Loading...
                          </div>
                          <div v-else>
                                Sync
                          </div>
                        </b-button>
                      </b-col>
                      <b-col>
                        
                      </b-col>
                    </b-row>
                  </b-container>
              <validation-observer ref="simpleRules1">
                <b-form>
                  <b-row  v-for="(data, index) in PowerMeterData">
                     <b-col
                      md="2"
                    >
                      <p sytle="margin-top:50px">
                      &nbsp;
                      </p>{{data.parameter}}
                    </b-col>
                    <b-col md="3">
                      <b-form-group>
                        <label>Min</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Min Value"
                        >
                          <b-form-input
                            v-model="data.min_val"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Min"
                            type="number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group>
                        <label>Max</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Max Value"
                        >
                          <b-form-input
                            v-model="data.max_val"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Min"
                            type="number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                      <b-col md="2">
                      <b-form-group>
                        <p sytle="margin-top:50px">
                            &nbsp;
                      </p>
                         <b-form-checkbox
                            v-model="data.alarm"
                            name="checkbox-alarm"
                            value="1"
                            unchecked-value="0"
                          >
                           Alarm
                          </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group>
                        <p sytle="margin-top:50px">
                            &nbsp;
                      </p>
                         <b-form-checkbox
                            v-model="data.routine"
                            name="checkbox-routine"
                            value="1"
                            unchecked-value="0"
                          >
                           Routine
                          </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <b-form-group>
                        <label />
                        <br>
                      </b-form-group>
                    </b-col>
                    <!-- submit button -->
                    <b-col md="12">
                      <b-button
                       :disabled="pmDisable"
                        variant="primary"
                        type="submit"
                        @click.prevent="validationForm1"
                      >
                        Save
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-row>

          </div>

          <div class="mx-2 mb-2">
            <b-row />
          </div>
        </b-card>
      </b-tab>
      <!-- untuk tab scrap im -->
      <b-tab title="Mixing">
        <b-card
          no-body
          class="mb-0"
        >

          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <validation-observer ref="simpleRules2">
                <b-form>
                  <b-row  v-for="(data, index) in MixingData">
                     <b-col
                      md="2"
                    >
                      <p sytle="margin-top:50px">
                      &nbsp;
                      </p>{{data.parameter}}
                    </b-col>
                    <b-col md="3">
                      <b-form-group>
                        <label>Min</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Mix Min Value"
                        >
                          <b-form-input
                            v-model="data.min_val"
                            :readonly="data.id_limit != null"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Min"
                            type="number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group>
                        <label>Max</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Mix Max Value"
                        >
                          <b-form-input
                            v-model="data.max_val"
                            :readonly="data.id_limit != null"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Min"
                            type="number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                      <b-col md="2">
                      <b-form-group>
                        <p sytle="margin-top:50px">
                            &nbsp;
                      </p>
                         <b-form-checkbox
                            v-model="data.alarm"
                            name="mix-checkbox-alarm"
                            value="1"
                            unchecked-value="0"
                          >
                           Alarm
                          </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group>
                        <p sytle="margin-top:50px">
                            &nbsp;
                      </p>
                         <b-form-checkbox
                            v-model="data.routine"
                            name="mix-checkbox-routine"
                            value="1"
                            unchecked-value="0"
                          >
                           Routine
                          </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <b-form-group>
                        <label />
                        <br>
                      </b-form-group>
                    </b-col>
                    <!-- submit button -->
                    <b-col md="12">
                      <b-button
                        variant="primary"
                        type="submit"
                        @click.prevent="validationForm2"
                      >
                        Save
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-row>
          </div>
        </b-card>
      </b-tab>
      <!-- tab Fitting -->
      <b-tab title="Mixing Azo">
        <b-card
          no-body
          class="mb-0"
        >

          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <validation-observer ref="simpleRules3">
                <b-form>
                  <b-row  v-for="(data, index) in AzoData">
                     <b-col
                      md="2"
                    >
                      <p sytle="margin-top:50px">
                      &nbsp;
                      </p>{{data.parameter}}
                    </b-col>
                    <b-col md="3">
                      <b-form-group>
                        <label>Min</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Azo Min Value"
                        >
                          <b-form-input
                            v-model="data.min_val"
                            :readonly="data.id_limit != null"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Min"
                            type="number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group>
                        <label>Max</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Azo Max Value"
                        >
                          <b-form-input
                            v-model="data.max_val"
                            :readonly="data.id_limit != null"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Min"
                            type="number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                      <b-col md="2">
                      <b-form-group>
                        <p sytle="margin-top:50px">
                            &nbsp;
                      </p>
                         <b-form-checkbox
                            v-model="data.alarm"
                            name="azo-checkbox-alarm"
                            value="1"
                            unchecked-value="0"
                          >
                           Alarm
                          </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group>
                        <p sytle="margin-top:50px">
                            &nbsp;
                      </p>
                         <b-form-checkbox
                            v-model="data.routine"
                            name="azo-checkbox-routine"
                            value="1"
                            unchecked-value="0"
                          >
                           Routine
                          </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <b-form-group>
                        <label />
                        <br>
                      </b-form-group>
                    </b-col>
                    <!-- submit button -->
                    <b-col md="12">
                      <b-button
                        variant="primary"
                        type="submit"
                        @click.prevent="validationForm3"
                      >
                        Save
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-row>
          </div>
        </b-card>
      </b-tab>

      <!-- untuk tab scrap im -->
      <b-tab title="Pelletizer">
        <b-card
          no-body
          class="mb-0"
        >

          <div class="m-2">

            <!-- Table Top -->
            <b-row>
              <b-container>
                <b-row>
                  <b-col md="4">
                    <b-form-group>
                        <b-form-input
                          v-model="PelletizerFilter"
                          placeholder="Search"
                          v-on:keyup.enter="filterPelletizer()"
                        />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-button 
                      variant="primary"
                      type="submit"
                      :disabled="plDisable"
                      @click.prevent="validationForm4"
                    >
                      Save
                    </b-button>
                    <b-button class="ml-1"
                      variant="success"
                      type="submit"
                      :disabled="plDisable"
                      @click.prevent="synTableMES"
                    >
                      <div v-if="plDisable">
                        <b-spinner small></b-spinner>
                          Loading...
                      </div>
                      <div v-else>
                            Sync
                      </div>
                    </b-button>
                  </b-col>
                  <b-col>
                  </b-col>
                </b-row>
              </b-container>
              <validation-observer ref="simpleRules2">
                <b-form>
                  <b-row  v-for="(data, index) in PelletizerData">
                     <b-col
                      md="3"
                    >
                      <p sytle="margin-top:50px">
                      &nbsp;
                      </p>{{data.parameter}}
                    </b-col>
                    <b-col md="3">
                      <b-form-group>
                        <label>Min</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Pel Min Value"
                        >
                          <b-form-input
                            v-model="data.min_val"
                            :readonly="data.id_limit != null"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Min"
                            type="number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group>
                        <label>Max</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Pel Max Value"
                        >
                          <b-form-input
                            v-model="data.max_val"
                            :readonly="data.id_limit != null"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Min"
                            type="number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                      <b-col md="1">
                      <b-form-group>
                        <p sytle="margin-top:50px">
                            &nbsp;
                      </p>
                         <b-form-checkbox
                            v-model="data.alarm"
                            name="pel-checkbox-alarm"
                            value="1"
                            unchecked-value="0"
                          >
                           Alarm
                          </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group>
                        <p sytle="margin-top:50px">
                            &nbsp;
                      </p>
                         <b-form-checkbox
                            v-model="data.routine"
                            name="pel-checkbox-routine"
                            value="1"
                            unchecked-value="0"
                          >
                           Routine
                          </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <b-form-group>
                        <label />
                        <br>
                      </b-form-group>
                    </b-col>
                    <!-- submit button -->
                    <b-col md="12">
                      <b-button
                        variant="primary"
                        type="submit"
                         :disabled="plDisable"
                        @click.prevent="validationForm4"
                      >
                        Save
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-row>
          </div>
        </b-card>
      </b-tab>
      <!-- end scm overview -->

      <!-- untuk tab scrap im -->
      <b-tab title="Tag Logger">
        <b-card
          no-body
          class="mb-0"
        >

          <div class="m-2">

            <!-- Table Top -->
            <b-row>
              <b-container>
                <b-row>
                  <b-col md="4">
                    <b-form-group>
                        <b-form-input
                          v-model="TagLoggerFilter"
                          placeholder="Search"
                          v-on:keyup.enter="filterTagLogger()"
                        />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-button 
                      variant="primary"
                      type="submit"
                      :disabled="tlDisable"
                      @click.prevent="validationForm5"
                    >
                      Save
                    </b-button>
                    <b-button class="ml-1"
                      variant="success"
                      type="submit"
                      :disabled="tlDisable"
                      @click.prevent="synTableMES"
                    >
                      <div v-if="tlDisable">
                        <b-spinner small></b-spinner>
                          Loading...
                      </div>
                      <div v-else>
                            Sync
                      </div>
                    </b-button>
                  </b-col>
                  <b-col>
                  </b-col>
                </b-row>
              </b-container>
              <validation-observer ref="simpleRules2">
                <b-form>
                  <b-container>
                  <b-row  v-for="(data, index) in TagLoggerData">
                     <b-col>
                      <p sytle="margin-top:50px">
                      &nbsp;
                      </p>{{data.parameter}}
                    </b-col>
                    <b-col md="3">
                      <b-form-group>
                        <label>Alias</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Alias"
                        >
                          <b-form-input
                            v-model="data.alias"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Alias"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group>
                        <label>Min</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Min Value"
                        >
                          <b-form-input
                            v-model="data.min_val"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Min"
                            type="number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group>
                        <label>Max</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Max Value"
                        >
                          <b-form-input
                            v-model="data.max_val"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Min"
                            type="number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                      <b-col>
                      <b-form-group>
                        <p sytle="margin-top:50px">
                            &nbsp;
                      </p>
                         <b-form-checkbox
                            v-model="data.alarm"
                            name="checkbox-alarm"
                            value="1"
                            unchecked-value="0"
                          >
                           Alarm
                          </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group>
                        <p sytle="margin-top:50px">
                            &nbsp;
                      </p>
                         <b-form-checkbox
                            v-model="data.routine"
                            name="checkbox-routine"
                            value="1"
                            unchecked-value="0"
                          >
                           Routine
                          </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  </b-container>
                  <b-row>
                    <b-col md="12">
                      <b-button
                        variant="primary"
                        type="submit"
                        @click.prevent="validationForm5"
                      >
                        Save
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-row>
          </div>
        </b-card>
      </b-tab>
      <!-- end SLD Overview -->
    </b-tabs>

    <!-- Control buttons-->
    <div class="text-center">
      <b-button-group class="mt-1">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="tabIndex--"
        >
          Previous
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="tabIndex++"
        >
          Next
        </b-button>
      </b-button-group>

      <b-card-text>
        <!-- <small>Current Tab: {{ tabIndex }}</small> -->
      </b-card-text>
    </div>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BButtonGroup, BTab, BTabs, BFormGroup,
  BFormCheckbox,BContainer,BForm,BCardText,BSpinner // BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-cycle
import {
  required,
} from '@validations'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-cycle
import useList from './useList'
import StoreModule from '../StoreModule'

export default {
  components: {
    BCard,
    BRow,
    BForm,
    BCol,
    BFormInput,
    BButton,
    BFormCheckbox,
    BContainer,
    BCardText,
    BSpinner,
    // BDropdown,
    // BDropdownItem,
    BButtonGroup,
    BTab,
    BTabs,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    required,
    flatPickr,
    vSelect,
    BFormGroup,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    tabIndex: 0,
  }),
  created(){

  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    
    const PowerMeterData = ref([])
    onMounted(async () => {
      const result = await axios.get(`${useAppConfig().AxiosUrl}api_mes/chatbot-setting/powermeter`)
      PowerMeterData.value = result.data;
    })

    const MixingData = ref([])
    onMounted(async () => {
      const result = await axios.get(`${useAppConfig().AxiosUrl}api_mes/chatbot-setting/mixing`, { params: {
          jenis:'mixing'
        } 
      })
      MixingData.value = result.data;
    })

    const AzoData = ref([])
    onMounted(async () => {
      const result = await axios.get(`${useAppConfig().AxiosUrl}api_mes/chatbot-setting/mixing`, { params: {
          jenis:'azo'
        } 
      })
      AzoData.value = result.data;
    })

    const PelletizerData = ref([])
    onMounted(async () => {
      const result = await axios.get(`${useAppConfig().AxiosUrl}api_mes/chatbot-setting/pelletizer`)
      PelletizerData.value = result.data;
    })

    const TagLoggerData = ref([])
    onMounted(async () => {
      const result = await axios.get(`${useAppConfig().AxiosUrl}api_mes/chatbot-setting/tag-logger`)
      TagLoggerData.value = result.data;
    })
     
    const PowerMeterFilter = ref(null);
    const PowerMeterTemp = ref([]);
    const pmDisable= ref(false);
    const PelletizerFilter = ref(null);
    const PelletizerTemp = ref([]);
    const plDisable= ref(false);
    const TagLoggerFilter = ref(null);
    const TagLoggerTemp = ref([]);
    const tlDisable= ref(false);
    const {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,

      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList()

    return {
     

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,

      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      PowerMeterData,
      MixingData,
      AzoData,
      PelletizerData,
      TagLoggerData,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      PowerMeterFilter,
      pmDisable,
      PelletizerFilter,
      plDisable,
      TagLoggerFilter,
      tlDisable,
    }
  },
  methods: {
    validationForm1() {
      this.$refs.simpleRules1.validate().then(async success => {
        if (success) {
          const params = new URLSearchParams()
          params.append('jenis', this.tabIndex)
          params.append('data', JSON.stringify(this.PowerMeterData))
          params.append('id', window.localStorage.getItem("id"))
          const result = await axios.post(`${useAppConfig().AxiosUrl}api_mes/chatbot-setting/powermeter-crud`, params)

          if (result.data.message === 'Data Updated') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: 'Data Updated',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Danger',
                icon: 'BellIcon',
                text: 'Error Saving Data!',
                variant: 'danger',
              },
            })
          } 
        }
      })
    },
    validationForm2() {
      this.$refs.simpleRules2.validate().then(async success => {
        if (success) {
          const params = new URLSearchParams()
          params.append('jenis', this.tabIndex)
          params.append('data', JSON.stringify(this.MixingData))
          params.append('id', window.localStorage.getItem("id"))
          const result = await axios.post(`${useAppConfig().AxiosUrl}api_mes/chatbot-setting/mixing-crud`, params)

          if (result.data.message === 'Data Updated') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: 'Data Updated',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Danger',
                icon: 'BellIcon',
                text: 'Error Saving Data!',
                variant: 'danger',
              },
            })
          } 
        }
      })
    },
    validationForm3() {
      this.$refs.simpleRules3.validate().then(async success => {
        if (success) {
          const params = new URLSearchParams()
          params.append('jenis', this.tabIndex)
          params.append('data', JSON.stringify(this.AzoData))
          params.append('id', window.localStorage.getItem("id"))
          const result = await axios.post(`${useAppConfig().AxiosUrl}api_mes/chatbot-setting/mixing-crud`, params)

          if (result.data.message === 'Data Updated') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: 'Data Updated',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Danger',
                icon: 'BellIcon',
                text: 'Error Saving Data!',
                variant: 'danger',
              },
            })
          } 
        }
      })
    },
    validationForm4() {
      this.$refs.simpleRules2.validate().then(async success => {
        if (success) {
          const params = new URLSearchParams()
          params.append('jenis', this.tabIndex)
          params.append('data', JSON.stringify(this.PelletizerData))
          params.append('id', window.localStorage.getItem("id"))
          const result = await axios.post(`${useAppConfig().AxiosUrl}api_mes/chatbot-setting/pelletizer-crud`, params)

          if (result.data.message === 'Data Updated') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: 'Data Updated',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Danger',
                icon: 'BellIcon',
                text: 'Error Saving Data!',
                variant: 'danger',
              },
            })
          } 
        }
      })
    },
    validationForm5() {
       this.$refs.simpleRules2.validate().then(async success => {
        if (success) {
          const params = new URLSearchParams()
          params.append('jenis', this.tabIndex)
          params.append('data', JSON.stringify(this.TagLoggerData))
          params.append('id', window.localStorage.getItem("id"))
          const result = await axios.post(`${useAppConfig().AxiosUrl}api_mes/chatbot-setting/tag-logger-crud`, params)

          if (result.data.message === 'Data Updated') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: 'Data Updated',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Danger',
                icon: 'BellIcon',
                text: 'Error Saving Data!',
                variant: 'danger',
              },
            })
          } 
        }
      }) 
    },
    async synTableMES() {
        let data=[];
        if(this.tabIndex == 0){
          data = {table : 1};
          this.pmDisable = true;
        }else if (this.tabIndex == 3){
          data = {table : 2};
          this.plDisable = true;
        }else if (this.tabIndex == 4){
          data = {table : 3};
          this.tlDisable = true;
        }
        const result = await axios.post(`${useAppConfig().AxiosUrl}api_mes/chatbot-setting/sync-table`, data)
        if (result.data.message === 'Data Updated') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              text: 'Data Updated',
              variant: 'success',
            },
          })
          if(this.tabIndex == 0){
            this.pmDisable = false;
            await this.getPowerMeter();
            this.PowerMeterData =  this.PowerMeterTemp;
          }else if (this.tabIndex == 3){
            this.plDisable = false;
            await this.getPelletizer();
            this.PelletizerData =  this.PelletizerTemp;
          }else if (this.tabIndex == 4){
            this.tlDisable = false;
            await this.getTagLogger();
            this.TagLoggerData =  this.TagLoggerTemp;
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Danger',
              icon: 'BellIcon',
              text: 'Error Saving Data!',
              variant: 'danger',
            },
          })
           if(this.tabIndex == 0){
            this.pmDisable = false;
          }else if (this.tabIndex == 3){
            this.plDisable = false;
          }else if (this.tabIndex == 4){
            this.tlDisable = false;
          }
        } 
    },
     async filterPowermeter() {
      let value = this.PowerMeterFilter.toUpperCase();
      if (this.PowerMeterFilter !== ''){
          await this.getPowerMeter();
          this.PowerMeterData =  this.PowerMeterTemp;
          this.PowerMeterData= this.PowerMeterData.filter((word) =>{
           let paramaterFilter = word.parameter;
           paramaterFilter = paramaterFilter.toUpperCase(); 
           //console.log(paramaterFilter.toUpperCase());
           return paramaterFilter.match(value);
          });
      }else{
        this.getPowerMeter();
        this.PowerMeterData =  this.PowerMeterTemp;
      }
    },
    async getPowerMeter() {
      const result = await axios.get(`${useAppConfig().AxiosUrl}api_mes/chatbot-setting/powermeter`)
      this.PowerMeterTemp = result.data;
      await this.PowerMeterTemp.forEach((el,index) => {
        //let obj = tempArray.find(o => o.id_machine === el.ID_Machine && o.mold == el.Mold);
        let obj = this.PowerMeterData.find(o => o.id === el.id);
        if (obj){
           this.PowerMeterTemp[index]=obj;
        }
      });
    },
     async filterPelletizer() {
      let value = this.PelletizerFilter.toUpperCase();
      if (this.PelletizerFilter !== ''){
          await this.getPelletizer();
          this.PelletizerData =  this.PelletizerTemp;
          this.PelletizerData= this.PelletizerData.filter((word) =>{
           let paramaterFilter = word.parameter;
           paramaterFilter = paramaterFilter.toUpperCase(); 
           //console.log(paramaterFilter.toUpperCase());
           return paramaterFilter.match(value);
          });
      }else{
        this.getPelletizer();
        this.PelletizerData =  this.PelletizerTemp;
      }
    },
    async getPelletizer() {
      const result = await axios.get(`${useAppConfig().AxiosUrl}api_mes/chatbot-setting/pelletizer`)
      this.PelletizerTemp = result.data;
      await this.PelletizerTemp.forEach((el,index) => {
        //let obj = tempArray.find(o => o.id_machine === el.ID_Machine && o.mold == el.Mold);
        let obj = this.PelletizerData.find(o => o.id === el.id);
        if (obj){
           this.PelletizerTemp[index]=obj;
        }
      });
    },
    async filterTagLogger() {
      let value = this.TagLoggerFilter.toUpperCase();
      console.log(value);
      if (this.TagLoggerFilter !== ''){
          await this.getTagLogger();
          this.TagLoggerData =  this.TagLoggerTemp;
          this.TagLoggerData= this.TagLoggerData.filter((word) =>{
           let paramaterFilter = word.parameter;
           let aliasFilter = word.alias == null?'':word.alias;
           
           paramaterFilter = paramaterFilter.toUpperCase();
           aliasFilter = aliasFilter.toUpperCase(); 
           //console.log(paramaterFilter.toUpperCase());
           return paramaterFilter.match(value)||aliasFilter.match(value);
          });
      }else{
        this.getTagLogger();
        this.TagLoggerData =  this.TagLoggerTemp;
      }
    },
    async getTagLogger() {
      const result = await axios.get(`${useAppConfig().AxiosUrl}api_mes/chatbot-setting/tag-logger`)
      this.TagLoggerTemp = result.data;
      await this.TagLoggerTemp.forEach((el,index) => {
        //let obj = tempArray.find(o => o.id_machine === el.ID_Machine && o.mold == el.Mold);
        let obj = this.TagLoggerData.find(o => o.id === el.id);
        if (obj){
           this.TagLoggerTemp[index]=obj;
        }
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
